// notificationsSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = [];

const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    enqueueSnackbar: (state, action) => {
      state.push({
        ...action.payload,
        key: action.payload.key || new Date().getTime() + Math.random(),
      });
    },
    removeSnackbar: (state, action) => {
      return state.filter(
        (notification) => notification.key !== action.payload,
      );
    },
  },
});

export const { enqueueSnackbar, removeSnackbar } = notificationsSlice.actions;

// Memoized selector
export const selectNotifications = createSelector(
  (state) => state.notifications,
  (notifications) => notifications,
);

export default notificationsSlice.reducer;
