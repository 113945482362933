import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import { fetchUnlinkedAccounts } from "./../slices/accounts";

const LinkAccountDialog = ({ open, onClose, onLink }) => {
  const dispatch = useDispatch();
  const { unlinkedAccounts } = useSelector((state) => state.accounts);

  useEffect(() => {
    if (open) {
      dispatch(fetchUnlinkedAccounts());
    }
  }, [open, dispatch]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Link an Account</DialogTitle>
      <DialogContent>
        <List>
          {unlinkedAccounts.map((account) => {
            const { id, houseNumber, propertyAddress } = account;
            return (
              <ListItem key={id} button onClick={() => onLink(id)}>
                <ListItemText primary={`${houseNumber} ${propertyAddress}`} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <Button onClick={onClose}>Cancel</Button>
    </Dialog>
  );
};

export default LinkAccountDialog;
