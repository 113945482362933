import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";

import { auth } from "./../firebase_config";
import { enqueueSnackbar } from "./notifications";

const initialState = {
  user: {},
  loading: true,
  error: null,
};

// Helper function to extract serializable user data
const extractUserData = (user) => {
  return user ? { uid: user.uid, email: user.email } : null;
};

// Async thunk for signing up
export const signUp = createAsyncThunk(
  "auth/signUp",
  async (
    { email, password, ...additionalInfo },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      // Add user to Realtime Database
      const db = getDatabase();
      const { uid } = userCredential?.user;
      const userRef = ref(db, "users/" + uid);
      await set(userRef, {
        email,
        ...additionalInfo,
        createdAt: Date.now(),
        uid,
      });

      dispatch(
        enqueueSnackbar({
          message: "User created!",
          options: {
            variant: "success",
          },
        }),
      );

      return {
        user: {
          ...extractUserData(userCredential.user),
          uid,
          ...additionalInfo,
        },
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: error,
          options: {
            variant: "error",
          },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for signing in
export const signIn = createAsyncThunk(
  "auth/signIn",
  async ({ email, password }, { rejectWithValue, dispatch }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password,
      );

      // Fetch user info from Realtime Database
      const db = getDatabase();
      const { uid } = userCredential?.user;
      const userRef = ref(db, "users/" + uid);
      const snapshot = await get(userRef);
      const userInfo = snapshot.val();
      dispatch(
        enqueueSnackbar({
          message: "Signed In",
          options: {
            variant: "success",
          },
        }),
      );

      return {
        user: { ...extractUserData(userCredential.user), uid, ...userInfo },
      };
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: error,
          options: {
            variant: "error",
          },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

// Async thunk for password reset (unchanged)
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email }, { rejectWithValue, dispatch }) => {
    try {
      await sendPasswordResetEmail(auth, email);
      dispatch(
        enqueueSnackbar({
          message:
            "Reset link sent. Please allow several minutes for reset.  Check your junk folder",
          options: {
            variant: "success",
          },
        }),
      );
      return "Password reset email sent";
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: error,
          options: {
            variant: "error",
          },
        }),
      );
      return rejectWithValue(error.message);
    }
  },
);

export const signOut = createAsyncThunk(
  "auth/signOut",
  async (_, { rejectWithValue }) => {
    try {
      await firebaseSignOut(auth);
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Sign Up
      .addCase(signUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Sign In
      .addCase(signIn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Reset Password (unchanged)
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(signOut.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.user = {};
      })
      .addCase(signOut.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setUser } = authSlice.actions;
export default authSlice.reducer;
