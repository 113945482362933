import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@mui/material";

import { addAccount } from "./../slices/accounts";

const initialValues = {
  houseNumber: "",
  propertyAddress: "Olivia Dr.",
  accountBalance: 0,
  amountDue: 0,
};

const NewAccountDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialValues);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      addAccount({
        houseNumber: formData.houseNumber,
        propertyAddress: formData.propertyAddress,
        accountBalance: Math.round(parseFloat(formData.accountBalance) * 100),
        amountDue: 0,
      }),
    );
    onClose();
    setFormData(initialValues);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add New Account</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="houseNumber"
            label="House Number"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.houseNumber}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="propertyAddress"
            label="Property Address"
            type="text"
            fullWidth
            variant="outlined"
            value={formData.propertyAddress}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            name="accountBalance"
            label="Account Balance"
            type="number"
            fullWidth
            variant="outlined"
            value={formData.accountBalance}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            inputProps={{
              step: "0.01",
            }}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained" color="primary">
            Add Account
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewAccountDialog;
