import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, push, serverTimestamp } from "firebase/database";
import { httpsCallable } from "firebase/functions";

import { db, functions } from "./../firebase_config";
import { enqueueSnackbar } from "./notifications";

export const processSquarePayment = createAsyncThunk(
  "payment/processSquarePayment",
  async (
    { sourceId, amount, currency, locationId },
    { rejectWithValue, getState, dispatch },
  ) => {
    try {
      const userId = getState().auth.user.uid; // Assuming you store user ID in auth slice
      const response = await fetch(
        "https://us-central1-shelby-estates-hoa.cloudfunctions.net/processSquarePayment-processSquarePayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            sourceId,
            amount,
            currency,
            locationId,
            userId,
          }),
        },
      );

      const data = await response.json();

      if (response.ok && data.success) {
        dispatch(
          enqueueSnackbar({
            message: `Payment processed`,
            options: {
              variant: "success",
            },
          }),
        );
        return data;
      } else {
        dispatch(
          enqueueSnackbar({
            message: `Error processing Square payment: ${data.error}`,
            options: {
              variant: "error",
            },
          }),
        );
        return rejectWithValue(data.error || "Payment processing failed");
      }
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: `Error processing Square payment: ${error}`,
          options: {
            variant: "error",
          },
        }),
      );
      console.error("Error processing Square payment:", error);
      return rejectWithValue(
        error.message || "An error occurred while processing your payment",
      );
    }
  },
);

const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    loading: false,
    error: null,
    lastPaymentId: null,
  },
  reducers: {
    clearPaymentError: (state) => {
      state.error = null;
    },
    clearTxnId: (state) => {
      state.lastPaymentId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processSquarePayment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(processSquarePayment.fulfilled, (state, action) => {
        state.loading = false;
        state.lastPaymentId = action.payload.paymentId;
      })
      .addCase(processSquarePayment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearPaymentError, clearTxnId } = paymentSlice.actions;

export default paymentSlice.reducer;
