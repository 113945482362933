import { configureStore } from "@reduxjs/toolkit";
import auth from "./slices/auth";
import notifications from "./slices/notifications";
import accounts from "./slices/accounts";
import user from "./slices/user";
import payments from "./slices/payments";

export const store = configureStore({
  reducer: {
    auth,
    notifications,
    accounts,
    user,
    payments,
    // Add other reducers here
  },
});
