import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onAuthStateChanged } from "firebase/auth";
import { ref, get } from "firebase/database";
import { Navigate, useLocation } from "react-router-dom";

import { setUser } from "./../slices/auth";
import { auth, db } from "./../firebase_config";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const { loading, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (user) {
  //       // Fetch user info including role from the database
  //       const userRef = ref(db, 'users/' + user.uid);
  //       const snapshot = await get(userRef);
  //       const userInfo = snapshot.val();

  //       dispatch(setUser({
  //         uid: user.uid,
  //         email: user.email,
  //         role: userInfo?.role || 'user', // Default to 'user' if role is not set
  //         ...userInfo
  //       }));
  //     } else {
  //       dispatch(setUser(null));
  //     }
  //   //   setLoading(false);
  //   });

  //   return () => unsubscribe();
  // }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return <AuthContext.Provider value={{}}>{children}</AuthContext.Provider>;
};

export const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const { uid, role } = useSelector((state) => state.auth.user);
  const location = useLocation();

  if (!uid) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
    // Redirect to an unauthorized page or show an error message
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
};
