import { round } from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PaymentForm,
  CreditCard,
  GooglePay,
  Ach,
} from "react-square-web-payments-sdk";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
//export 'PaymentsForm' (imported as 'PaymentsForm') was not found in 'react-square-web-payments-sdk'
//(possible exports: Ach, Afterpay, AfterpayButton, AfterpayContext, AfterpayMessage, AfterpayProvider,
//AfterpayWidget, ApplePay, CashAppPay, CreditCard, Divider, GiftCard, GooglePay, PaymentForm, PlaidLinkStableEvent)
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Divider,
  Typography,
  Box,
  CircularProgress,
  Alert,
  useTheme,
} from "@mui/material";
import {
  processSquarePayment,
  clearPaymentError,
  clearTxnId,
} from "./../slices/payments"; // We'll create this later

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(1),
  borderColor: theme.palette.primary.contrastText,
  borderWidth: "1px",
  borderStyle: "solid",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  width: "100%",
  // fontSize: theme.typography.htmlFontSize,
  // lineHeight: theme.typography.button.lineHeight,
}));

const SquarePaymentForm = ({ pay, same }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [txId, setTxId] = useState(null);

  const { loading, error, lastPaymentId } = useSelector(
    (state) => state.payments,
  );

  const handlePaymentMethodSubmit = async (token) => {
    if (token.status === "OK") {
      dispatch(
        processSquarePayment({
          sourceId: token.token,
          amount: pay, // $10.00 in cents
          currency: "USD",
          locationId: process.env.REACT_APP_SQUARE_LOCATION_ID,
        }),
      );
    } else {
      dispatch(
        processSquarePayment.rejected(
          null,
          null,
          "Payment token generation failed",
        ),
      );
    }
  };
  const createPaymentRequest = () => {
    return {
      countryCode: "US",
      currencyCode: "USD",
      total: {
        label: "Total",
        amount: (pay / 100).toFixed(2), // Amount in dollars
        pending: false,
      },
      requestShippingAddress: false,
    };
  };

  const handleNonceResponseReceived = async (errors, nonce, cardData) => {
    if (errors) {
      console.error(errors);
      return;
    }
    try {
      const response = await fetch("/process-payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ nonce }),
      });

      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
  };
  React.useEffect(() => {
    setTxId(uuidv4());
  }, [pay]);
  React.useEffect(() => {
    // Clear error when component unmounts
    return () => {
      dispatch(clearPaymentError());
    };
  }, [dispatch]);

  // return loading ? (
  //   <Box sx={{ maxWidth: 400, margin: "auto" }}>
  //     <CircularProgress color="secondary" />
  //   </Box>
  // ) :
  return (
    <Box sx={{ maxWidth: 400, margin: "auto" }}>
      {lastPaymentId ? (
        <>
          <Alert severity="success">
            Payment processed successfully! Payment ID:{" "}
            {JSON.stringify(lastPaymentId)}
          </Alert>
          <Button
            color="info"
            variant="contained"
            sx={{ mt: 1 }}
            onClick={() => dispatch(clearTxnId())}
          >
            Make Another Payment
          </Button>
        </>
      ) : (
        txId && (
          <PaymentForm
            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
            locationId={process.env.REACT_APP_SQUARE_LOCATION_ID}
            cardTokenizeResponseReceived={handlePaymentMethodSubmit}
            createPaymentRequest={createPaymentRequest}
            transactionId={txId}
          >
            <CreditCard
              postalCode="70301"
              buttonProps={{
                css: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  marginBottom: theme.spacing(1),
                  borderColor: theme.palette.primary.contrastText,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  width: "100%",
                },
              }}
              // render={butt => {
              //   return loading ? (
              //     <LoadingButton />
              //   ) : (
              //     <StyledButton>{`Pay $10`}</StyledButton>
              //   );
              // }}
            >
              {same ? "Pay Total" : `Pay $${round(pay / 100, 2)}`}
            </CreditCard>
            <Divider />
            <GooglePay />
            <Divider />
            <Ach
              accountHolderName="John Doe"
              redirectURI="https://google.com"
              transactionId="1234"
              buttonProps={{
                css: {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  marginBottom: theme.spacing(1),
                  borderColor: theme.palette.primary.contrastText,
                  borderWidth: "1px",
                  borderStyle: "solid",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.dark,
                  },
                  width: "100%",
                },
              }}
            />
            {
              //   <Button
              //     variant="contained"
              //     color="primary"
              //     type="submit"
              //     sx={{ mt: 2 }}
              //     disabled={loading}
              //   >
              //     {loading ? <CircularProgress size={24} /> : "Pay"}
              //   </Button>
            }
          </PaymentForm>
        )
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {JSON.stringify(error)}
        </Alert>
      )}
    </Box>
  );
};

export default SquarePaymentForm;
