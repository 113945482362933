import { round } from "lodash";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  Paper,
  Divider,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import {
  linkAccountToUser,
  fetchUserAccount,
  fetchRecentTransactions,
} from "./../slices/user";
import LinkAccountDialog from "./LinkAccountDialog";
import SquarePaymentForm from "./SquarePaymentForm";

const UserPage = () => {
  const dispatch = useDispatch();
  const [pay, setPay] = useState(0);
  const [editAmount, setEditAmount] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { linkedAccount, recentTransactions } = useSelector(
    (state) => state.user,
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  useEffect(() => {
    if (linkedAccount?.amountDue) {
      setPay(round(linkedAccount.amountDue / 100, 2));
    }
  }, [linkedAccount]);
  useEffect(() => {
    if (user) {
      dispatch(fetchUserAccount(user.uid));
      dispatch(fetchRecentTransactions(user.uid));
    }
  }, [dispatch, user]);

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);
  const updatePayment = (e) => {
    const { value } = e.target;
    setPay(value);
  };
  const handleLinkAccount = (accountId) => {
    dispatch(linkAccountToUser({ userId: user.uid, accountId }));
    handleCloseDialog();
  };

  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString();
  };

  const toggleAmount = () => setEditAmount((p) => !p);

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: 2,
        textAlign: "center",
        maxWidth: (t) => t.breakpoints.values.md,
        margin: (t) => `${t.spacing(2)} auto`,
      }}
    >
      <Grid container sx={{ p: 2 }}>
        <Grid
          item
          xs={12}
          sx={(t) => ({
            borderRadius: `${t.spacing(2)} ${t.spacing(2)} 0px 0px `,
            backgroundColor: t.palette.primary.main,
            color: t.palette.primary.contrastText,
            // mb: 2,
            p: 2,
          })}
        >
          {linkedAccount ? (
            <>
              <Typography variant="h4">Amount Due</Typography>
              <Typography
                variant="h3"
                sx={{ display: "inline-flex", alignItems: "center" }}
              >
                {formatBalance(linkedAccount.amountDue)}
                <Tooltip title="Pay Different Amount">
                  <IconButton
                    sx={{ color: (t) => t.palette.primary.contrastText, ml: 2 }}
                    onClick={toggleAmount}
                  >
                    <CreateIcon />
                  </IconButton>
                </Tooltip>
              </Typography>

              <SquarePaymentForm
                pay={pay * 100}
                same={pay * 100 === linkedAccount.amountDue}
              />
            </>
          ) : (
            <>
              <Typography variant="h6">No account linked</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
              >
                Link Account
              </Button>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h5" gutterBottom>
              Recent Transactions
            </Typography>
            <Grid container sx={{ fontWeight: "bold", py: 1 }}>
              <Grid item xs={3}>
                Date
              </Grid>
              <Grid item xs={5}>
                Description
              </Grid>
              <Grid item xs={2}>
                Type
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                Amount
              </Grid>
            </Grid>
            <Divider />
            {recentTransactions.map((transaction) => (
              <React.Fragment key={transaction.id}>
                <Grid container sx={{ py: 1 }}>
                  <Grid item xs={3}>
                    {formatDate(transaction.date)}
                  </Grid>
                  <Grid item xs={5}>
                    {transaction.description}
                  </Grid>
                  <Grid item xs={2}>
                    {transaction.type}
                  </Grid>
                  <Grid item xs={2} sx={{ textAlign: "right" }}>
                    {formatBalance(transaction.amount)}
                  </Grid>
                </Grid>
                <Divider />
              </React.Fragment>
            ))}
          </Paper>
        </Grid>
        <LinkAccountDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onLink={handleLinkAccount}
        />
        <Dialog open={editAmount} onClose={toggleAmount}>
          <DialogTitle>Edit Payment Amount</DialogTitle>
          <DialogContent>
            <TextField
              value={pay}
              type="number"
              onChange={updatePayment}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              inputProps={{
                step: "0.01",
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleAmount}>Close</Button>
            <Button onClick={toggleAmount} variant="outlined">
              Update
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Paper>
  );
};

export default UserPage;
