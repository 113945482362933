import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ref, set, get } from "firebase/database";

import { db } from "./../firebase_config";

export const linkAccountToUser = createAsyncThunk(
  "user/linkAccount",
  async ({ userId, accountId }, { dispatch, rejectWithValue }) => {
    try {
      await set(ref(db, `userAccounts/${userId}`), accountId);
      dispatch(fetchUserAccount(userId));
      return { userId, accountId };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUserAccount = createAsyncThunk(
  "user/fetchAccount",
  async (userId, { rejectWithValue }) => {
    try {
      const snapshot = await get(ref(db, `userAccounts/${userId}`));
      const accountId = snapshot.val();
      if (accountId) {
        const accountSnapshot = await get(ref(db, `accounts/${accountId}`));
        return { id: accountId, ...accountSnapshot.val() };
      }
      return null;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchRecentTransactions = createAsyncThunk(
  "user/fetchTransactions",
  async (userId, { rejectWithValue }) => {
    try {
      const snapshot = await get(ref(db, `transactions/${userId}`));
      const transactions = [];
      snapshot.forEach((childSnapshot) => {
        transactions.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      return transactions;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    linkedAccount: null,
    recentTransactions: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(linkAccountToUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetchUserAccount.fulfilled, (state, action) => {
        state.linkedAccount = action.payload;
        state.loading = false;
      })
      .addCase(fetchRecentTransactions.fulfilled, (state, action) => {
        state.recentTransactions = action.payload;
        state.loading = false;
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        },
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.payload;
        },
      );
  },
});

export default userSlice.reducer;
