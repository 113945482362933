// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, get, ref } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";

import { setUser, signOut } from "./slices/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDCDMAhqTdMcugUQiEwXYMblAw6BXLQeJ0",
  authDomain: "shelby-estates-hoa.firebaseapp.com",
  databaseURL: "https://shelby-estates-hoa-default-rtdb.firebaseio.com",
  projectId: "shelby-estates-hoa",
  storageBucket: "shelby-estates-hoa.appspot.com",
  messagingSenderId: "37551308236",
  appId: "1:37551308236:web:29dbdd717934ff8549b169",
  measurementId: "G-GNPQDNHE35",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Cloud Firestore and get a reference to the service
const db = getDatabase(app);

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage(app);

const functions = getFunctions(app);

export { app, auth, db, storage, functions };

// Helper function to extract serializable user data
const extractUserData = (user) => {
  return user ? { uid: user.uid, email: user.email } : null;
};

export const initializeAuthListener = (store) => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Fetch user info from Realtime Database
      const { uid } = user;
      const userRef = ref(db, "users/" + uid);
      const snapshot = await get(userRef);
      const userInfo = snapshot.val();
      store.dispatch(setUser({ ...extractUserData(user), ...userInfo }));
    } else {
      store.dispatch(signOut());
    }
  });
};
