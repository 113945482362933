import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Paper,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { deleteAccount, queryAccounts } from "./../slices/accounts";

import NewAccount from "./NewAccount";

function AccountsComponent() {
  const dispatch = useDispatch();
  const { accounts, loading, error } = useSelector((state) => state.accounts);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    dispatch(queryAccounts());
  }, [dispatch]);

  const handleDeleteAccount = (id) => {
    dispatch(deleteAccount(id));
  };

  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  const formatBalance = (cents) => {
    return (cents / 100).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      <h2>Accounts</h2>
      <Button onClick={handleOpenDialog} variant="contained" color="primary">
        Add New Account
      </Button>
      <List>
        {accounts.map((account) => (
          <ListItem key={account.id}>
            <ListItemText
              primary={`${account.houseNumber} ${account.propertyAddress}`}
              secondary={`Balance: ${formatBalance(account.accountBalance)}`}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteAccount(account.id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <NewAccount open={dialogOpen} onClose={handleCloseDialog} />
    </div>
  );
}

const Accounts = () => {
  return (
    <Box>
      <Paper elevation={1}>
        <AccountsComponent />
        <Grid container></Grid>
      </Paper>
    </Box>
  );
};

export default Accounts;
