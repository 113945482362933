import logo from "./logo.svg";
import "./App.css";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";

import { store } from "./store";
import { AuthProvider, ProtectedRoute } from "./providers/AuthProvider";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Reset from "./components/Reset";
import Account from "./components/Account";
import Accounts from "./components/Accounts";
import Notifier from "./components/Notifier";
import Unauthorized from "./components/Unauthorized";
import AppBar from "./components/AppBar";
import { initializeAuthListener } from "./firebase_config";

initializeAuthListener(store);

const defaultTheme = createTheme();

const Root = () => (
  <>
    <AppBar />
    <Outlet />
  </>
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/reset",
    element: <Reset />,
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "/*",
    element: <Root />,

    children: [
      {
        path: "account",
        element: (
          <ProtectedRoute>
            <Account />
          </ProtectedRoute>
        ),
      },
      {
        path: "accounts",
        element: (
          <ProtectedRoute allowedRoles={["admin"]}>
            <Accounts />
          </ProtectedRoute>
        ),
      },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Provider store={store}>
        <SnackbarProvider maxSnack={3}>
          <Notifier />
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
